import {
    Organisation,
    Adviser,
    InvestorAdviserRelationship,
    User,
    BankAccount,
    Account,
    TaxReferenceNumber,
    PowerOfAttorney,
    Accountant,
    EstateContact,
    AdditionalUser,
    AppQuestion,
    AppQuestionAnswer
} from '@/models';
import OrganisationTypeEnum from '@/enums/organisation/type';
import InvestorCategoryEnum from '@/enums/investor/category';
import UserTaxCertDestinationEnum from '@/enums/user/taxCertificateDestination';
import InvestorStructureEnum from '@/enums/investor/structure';
import AccountTypeEnum from '@/enums/account/type';
import InvestorRegistrationStatusEnum from '@/enums/investor/registrationStatus';
import InvestorAccountStageEnum from '@/enums/investor/accountStage';
import InvestorAccountStageStatusEnum from '@/enums/investor/accountStageStatus';
import UserRoleEnum from '@/enums/user/role';
import toDate from '@/lib/helpers/toDate';
import clock from '@/lib/clock';
import getFilterOperator from '@/lib/helpers/getFilterOperator';
import getValidator from '@/lib/helpers/getValidator';
import config from '@/config';
import ErrorService from '@/services/ErrorService';
import InvestorApi from '@/api/InvestorApi';
import isEmailMatch from '@/lib/helpers/isEmailMatch';

export class Investor extends Organisation {
    static entity = 'investors';
    static Api = InvestorApi;

    static fields() {
        return {
            ...super.fields(),
            type: this.enum(OrganisationTypeEnum, OrganisationTypeEnum.INVESTOR).nullable(),
            category: this.enum(InvestorCategoryEnum, 'HIGH_NET_WORTH').nullable(),
            category_expires_on: this.string(null).nullable(),
            category_authorised_on: this.string(null).nullable(),
            accounts: this.attr(null).nullable(),
            holdings: this.attr(null).nullable(),
            title: this.string(null).nullable(),
            name: this.string(null).nullable(),
            dob: this.string(null).nullable(),
            national_insurance: this.string(null).nullable(),
            nationality: this.attr(null).nullable(), // Alpha2Code
            domiciled: this.attr(null).nullable(), // Alpha2Code
            city_of_birth: this.string(null).nullable(),
            tax_residency: this.attr(null).nullable(),
            email: this.string(null).nullable(),
            us_person: this.boolean(null).nullable(),
            politically_exposed: this.boolean(null).nullable(),
            has_power_of_attorney: this.boolean(null).nullable(),
            power_of_attorney: this.modelList(PowerOfAttorney, null).nullable(), // List of POAs
            accountants: this.modelList(Accountant, null).nullable(), // List of accountants
            estate_of: this.modelList(EstateContact, null).nullable(), // List of estate contacts
            additional_users: this.modelList(AdditionalUser, null).nullable(), // List of additional users
            tax_cert_location: this.enum(UserTaxCertDestinationEnum, null).nullable(),
            terms_and_conditions: this.attr(null).nullable(),
            data_protection_agreement: this.attr(null).nullable(),
            covi: this.attr(null).nullable(),
            initial_transfer: this.attr(null).nullable(),
            bank_accounts: this.modelList(BankAccount, null).nullable(),
            bank_account_deferred: this.boolean(null).nullable(),
            adviser_accounts: this.modelList(InvestorAdviserRelationship, null).nullable(),
            aml_check_complete: this.string(null).nullable(),
            subscribed_notifications: this.attr([]),
            tax_reference_numbers: this.modelList(TaxReferenceNumber, null).nullable(),
            users: this.modelList(User, null).nullable(),
            fee_overall: this.attr(0).nullable(),
            fee_percentage_of_investment: this.attr(0).nullable(),
            fee_amount_per_annum: this.attr({ currency: 'GBP', amount: 0 }).nullable(),
            fee_vat: this.boolean(null).nullable(),
            appropriateness_completed_at: this.string(null).nullable(),
            appropriateness: this.modelList(AppQuestion, []).nullable(),
            investor_structure: this.enum(InvestorStructureEnum, InvestorStructureEnum.INDIVIDUAL).nullable(),
            recategorisation_attempts: this.number(0).nullable(),
            total_proposals: this.number(null).nullable(),
            proposals_amount: this.attr(null).nullable(),
            current_value: this.attr(null).nullable(),
            cash_balance: this.attr(null).nullable(),
            //
            onboarding_status: this.enum(
                InvestorRegistrationStatusEnum,
                InvestorRegistrationStatusEnum.COMPLETE
            ).nullable(),
            onboarding_status_percentage: this.number(null).nullable()
        };
    }

    static validation() {
        return {
            SettingsProfile: {
                title: 'required:title',
                name: 'required:name',
                email: ['required:email', 'email:email'],
                dob: 'required:dob',
                city_of_birth: 'required:city_of_birth',
                national_insurance: ['required:national_insurance', 'nationalInsuranceNumber:national_insurance'],
                nationality: 'required:nationality',
                domiciled: 'required:domiciled',
                addresses: [
                    'addresses:addresses',
                    'required:addresses',
                    v => getValidator('addressHistory')(v, 'Address history', 36)
                ],
                phone_numbers: ['phoneNumbers:phone_numbers', 'required:phone_numbers'],
                us_person: 'required:us_person',
                politically_exposed: 'required:politically_exposed',
                has_power_of_attorney: 'required:has_power_of_attorney'
            },
            SettingsBilling: {
                fee_overall: 'required:fee_overall',
                fee_percentage_of_investment: 'required:fee_percentage_of_investment',
                fee_amount_per_annum: 'required:fee_amount_per_annum'
            },
            SettingsInvestorBankAccount: {
                bank_accounts: 'bankAccountsOrDeferred:bank_accounts,bank_account_deferred'
            },
            SettingsAppropriateness: {
                // appropriateness_completed_at: 'required:appropriateness_completed_at',
                appropriateness: ['appropriateness:appropriateness', 'required:appropriateness']
            }
        };
    }

    // ---- Start Adviser helpers ----

    get has_address_history() {
        if (!this.addresses || this.addresses.length === 0) {
            return false;
        }

        const valid = getValidator('addressHistory')(this.addresses, 'Address history', 36);

        return valid === true;
    }

    get has_one_adviser() {
        return this.adviser_ids.length === 1;
    }

    get has_multiple_advisers() {
        return this.adviser_ids.length > 1;
    }

    get has_adviser_account() {
        return this.adviser_accounts && this.adviser_accounts.length > 0;
    }

    get adviser_accounts_awaiting_application() {
        if (!this.has_adviser_account) {
            return [];
        }

        return this.adviser_accounts.filter(aa => aa.investment_account_applied_at === null);
    }

    get adviser_accounts_not_awaiting_application() {
        if (!this.has_adviser_account) {
            return [];
        }

        return this.adviser_accounts.filter(aa => aa.investment_account_applied_at);
    }

    get adviser_accounts_awaiting_authorisation() {
        if (!this.has_adviser_account) {
            return [];
        }

        return this.adviser_accounts.filter(
            aa => aa.investment_account_applied_at && aa.investment_account_authorised_at === null
        );
    }

    get adviser_accounts_not_awaiting_authorisation() {
        if (!this.has_adviser_account) {
            return [];
        }

        return this.adviser_accounts.filter(aa => aa.investment_account_authorised_at);
    }

    get adviser_accounts_awaiting_signature() {
        if (!this.has_adviser_account) {
            return [];
        }

        return this.adviser_accounts.filter(aa => aa.investment_account_application_signed_at === null);
    }

    get adviser_accounts_awaiting_authorisation_signoff() {
        if (!this.has_adviser_account) {
            return [];
        }

        return this.adviser_accounts.filter(aa => aa.investment_account_authorised_signoff_by_id === null);
    }

    get is_gi_investor() {
        return (
            this.adviser_accounts &&
            this.adviser_accounts.some(aa => {
                return aa.adviser?.name === 'GrowthInvest';
            })
        );
    }

    get adviser_id() {
        if (!this.has_adviser_account) {
            return null;
        }

        return this.adviser_ids[0];
    }

    get adviser() {
        if (!this.has_adviser_account) {
            return new Adviser();
        }

        for (const aa of this.adviser_accounts) {
            if (aa.adviser_id === this.adviser_id) {
                return aa.adviser;
            }
        }

        return Adviser.find(this.adviser_id) || new Adviser();
    }

    get adviser_name() {
        return this.adviser.name || null;
    }

    get adviser_ids() {
        if (!this.has_adviser_account) {
            return [];
        }

        return this.adviser_accounts.map(aa => aa.adviser_id);
    }

    get advisers() {
        if (!this.has_adviser_account) {
            return [];
        }

        return this.adviser_accounts.map(aa => aa.adviser);
    }

    get adviser_account() {
        if (!this.adviser_accounts) {
            return new InvestorAdviserRelationship();
        }

        if (this.has_one_adviser) {
            return this.adviser_accounts[0];
        }

        return this.adviser_accounts.find(aa => aa.adviser_id === this.adviser_id) || new InvestorAdviserRelationship();
    }

    get primary_contact_id() {
        return this.adviser_account.primary_contact?.id || null;
    }

    get investment_account_applied_at() {
        return this.adviser_account.investment_account_applied_at || null;
    }

    get investment_account_authorised_at() {
        return this.adviser_account.investment_account_authorised_at || null;
    }

    get investment_account_opened_at() {
        return this.adviser_account.investment_account_opened_at || null;
    }

    get is_authorised() {
        return this.investment_account_authorised_at !== null;
    }

    get has_applied() {
        return this.investment_account_applied_at !== null;
    }

    isAccountAuthorised(adviserId) {
        return (
            this.adviser_accounts &&
            this.adviser_accounts.find(aa => aa.adviser_id === adviserId && aa.investment_account_authorised_at)
        );
    }

    // ---- End Adviser helpers ---- //

    get max_recategorisation_attempts_consumed() {
        return this.recategorisation_attempts >= this.max_recategorisation_attempts;
    }

    get exceeded_max_recategorisation_attempts() {
        return this.recategorisation_attempts > this.max_recategorisation_attempts;
    }

    get max_recategorisation_attempts() {
        return config.APP_MAX_ONBOARDING_INVESTOR_TYPE_ATTEMPTS || 3;
    }

    get max_categorisation_expiry_date() {
        return clock().add(1, 'years').add(1, 'days').format('YYYY-MM-DD');
    }

    get category_expired() {
        if (!this.category_expires_on) {
            ErrorService.logSentryEvent('Category expires on is falsy', this.category_expires_on);
            return true;
        }

        const date = toDate(this.category_expires_on, false);

        if (!date) {
            ErrorService.logSentryEvent('Category expires on is not a valid date', this.category_expires_on);
            return true;
        }

        return date.isBefore(clock());
    }

    get next_categorisation_date() {
        return toDate(this.category_expires_on);
    }

    get user() {
        if (!this.users || !this.users.length) {
            return new User();
        }

        if (!this.email) {
            return new User();
        }

        return this.users.find(user => isEmailMatch(user.email, this.email)) || new User();
    }

    get user_id() {
        if (!this.users || !this.users.length) {
            return null;
        }

        const owner = this.users.find(user => {
            if (!user.roles) {
                return false;
            }
            return user.roles.find(role => {
                return role.organisation_id === this.id && role.roles.includes(UserRoleEnum.OWNER);
            });
        });

        return owner ? owner.id : null;
    }

    get is_individual() {
        return this.investor_structure === InvestorStructureEnum.INDIVIDUAL;
    }

    get is_certified_sophisticated() {
        return this.category === InvestorCategoryEnum.CERTIFIED_SOPHISTICATED;
    }

    get is_elective_professional() {
        return this.category === InvestorCategoryEnum.ELECTIVE_PROFESSIONAL;
    }

    get is_high_net_worth() {
        return this.category === InvestorCategoryEnum.HIGH_NET_WORTH;
    }

    get is_professional() {
        return this.category === InvestorCategoryEnum.PROFESSIONAL;
    }

    get is_retail_advised() {
        return this.category === InvestorCategoryEnum.RETAIL_ADVISED;
    }

    get is_retail_restricted() {
        return this.category === InvestorCategoryEnum.RETAIL_RESTRICTED;
    }

    get is_self_cert_sophisticated() {
        return this.category === InvestorCategoryEnum.SELF_CERT_SOPHISTICATED;
    }

    get is_retail() {
        return this.is_retail_restricted || this.is_retail_advised;
    }

    get bank_account() {
        const bankAccount = (this.bank_accounts || []).find(b => b.primary);

        if (!bankAccount) {
            return null;
        }

        return new BankAccount(bankAccount);
    }

    get appropriateness_results() {
        if (!this.appropriateness) {
            return null;
        }
        return this.appropriateness.map(question => {
            question.answers = question.answers
                .filter(answer => {
                    if ((question.is_type_textarea || question.is_type_string) && !answer?.text) {
                        return false;
                    }
                    return true;
                })
                .map(answer => {
                    return answer instanceof AppQuestionAnswer ? answer.$toPayload() : answer;
                });

            return question instanceof AppQuestion ? question.$toPayload() : question;
        });
    }

    get platform_accounts() {
        if (!this.accounts || !this.accounts.length) {
            return [];
        }

        return this.accounts.filter(account => account.type === AccountTypeEnum.PLATFORM);
    }

    get fee_accounts() {
        if (!this.accounts || !this.accounts.length) {
            return [];
        }

        return this.accounts.filter(account => account.type === AccountTypeEnum.FEE);
    }

    async $hydrateAppropriateness(options = {}) {
        options = {
            force: false,
            ...options
        };

        if (this.$hydrated.includes('appropriateness') && !options.force) {
            return this;
        }

        const appropriateness = await this.$getAppropriateness({ force: options.force });
        this.$hydrated.push('appropriateness');

        Investor.update({ where: this.id, data: { appropriateness } });
        this.appropriateness = appropriateness;

        return this;
    }

    async $getAppropriateness(options = {}) {
        options = {
            force: false,
            ...options
        };

        if (!this.id) {
            return [];
        }

        const response = await Investor.api.getAppropriateness(this.id, { persistent: options.force });

        if (!('data' in response)) {
            return [];
        }

        if (!('questions' in response.data)) {
            return [];
        }

        return response.data.questions.map(question => {
            let answers = [];

            if ('answers' in question) {
                answers = question.answers.map(answer => new AppQuestionAnswer(answer));
            }

            return new AppQuestion({ ...question, answers });
        });
    }

    async $postAppropriateness() {
        return await Investor.api.postAppropriateness(this.id, this.appropriateness_results);
    }

    async $hydrateAdviserAccounts(options = {}) {
        options = {
            force: false,
            ...options
        };

        if (this.$hydrated.includes('adviser_accounts') && !options.force) {
            return this;
        }

        const adviser_accounts = await this.$getAdviserAccounts({ force: options.force });
        this.$hydrated.push('adviser_accounts');

        Investor.update({ where: this.id, data: { adviser_accounts } });
        this.adviser_accounts = adviser_accounts;

        return this;
    }

    async $getAdviserAccounts(options = {}) {
        options = {
            visibility_groups: true,
            full_adviser: true,
            ...options
        };

        const relationships = [];

        for (const adviser_id of this.adviser_ids) {
            try {
                const relationship = await InvestorAdviserRelationship.$get(this.id, adviser_id, {
                    force: options.force
                });
                if (relationship) {
                    relationships.push(relationship);
                }
            } catch (error) {
                if (![403, 404].includes(error?.code)) {
                    throw error;
                }
            }
        }

        if (options.full_adviser) {
            for (const adviser_id of this.adviser_ids) {
                try {
                    const relationship = relationships.find(r => r.adviser_id === adviser_id);
                    if (relationship) {
                        relationship.adviser = await Adviser.$get(adviser_id);
                    }
                } catch (error) {
                    if (![403, 404].includes(error?.code)) {
                        throw error;
                    }
                }
            }
        }

        if (options.visibility_groups) {
            if (this.Auth().is_gi || (this.Auth().is_adviser && this.Auth().has_role('SUPERADMIN'))) {
                const visibilityGroups = await this.$getVisibilityGroups();

                relationships.map(relationship => {
                    relationship.visibility_groups = visibilityGroups.filter(
                        vg => vg.adviser_id === relationship.adviser_id
                    );
                    return relationship;
                });
            }
        }

        return relationships;
    }

    async $getVisibilityGroups() {
        const response = await Investor.api.getVisibilityGroups(this.id);
        return [...response.data];
    }

    async $hydrateUsers(options = {}) {
        options = {
            force: false,
            ...options
        };

        if (this.$hydrated.includes('users') && !options.force) {
            return this;
        }

        const users = await this.$getUsers();
        this.$hydrated.push('users');

        Investor.update({ where: this.id, data: { users } });
        this.users = users;

        return this;
    }

    async $getUsers() {
        const users = [];

        if (!this.users || !this.users.length) {
            return users;
        }

        for (let user of this.users) {
            try {
                user = await User.$get(user.id);
                users.push(user);
            } catch (error) {
                if (![403, 404].includes(error?.code)) {
                    throw error;
                }
            }
        }

        return users;
    }

    async $hydrateAccounts(options = {}) {
        options = {
            force: false,
            ...options
        };

        if (this.$hydrated.includes('accounts') && !options.force) {
            return this;
        }

        const accounts = await this.$getAccounts(options);
        this.$hydrated.push('accounts');

        Investor.update({ where: this.id, data: { accounts } });
        this.accounts = accounts.map(acc => new Account(acc));

        return this;
    }

    async $getAccounts(options = {}) {
        options = {
            force: false,
            type: null,
            ...options
        };

        const accounts = [];

        if (!this.accounts || !this.accounts.length) {
            return accounts;
        }

        const filters = {
            id: `${getFilterOperator('in')}:[${this.accounts.map(acc => acc.id).join(',')}]`
        };

        if (options.type === 'FULL') {
            filters.full_object = true;
        } else if (options.type === 'SUMMARY') {
            filters.summary_object = true;
        }

        return (await Account.api.index(null, null, null, filters, { persistent: options.force })).data;
    }

    getAdviserPlatformAccount(adviserId) {
        return (
            this.platform_accounts.find(
                pa => pa.adviser_id === adviserId || (pa.adviser && pa.adviser.id === adviserId)
            ) || new Account()
        );
    }

    get is_profile_valid() {
        const valid = this.$validate('SettingsProfile');
        return Object.values(valid).every(res => res === true);
    }
    get is_billing_valid() {
        const valid = this.$validate('SettingsBilling');
        return Object.values(valid).every(res => res === true);
    }
    get is_bank_account_valid() {
        const valid = this.$validate('SettingsInvestorBankAccount');
        return Object.values(valid).every(res => res === true);
    }
    get is_appropriateness_valid() {
        const valid = this.$validate('SettingsAppropriateness');
        return Object.values(valid).every(res => res === true);
    }

    get is_ready_for_investment_account() {
        const valid = this.$validate();

        for (const key in valid) {
            if (Object.values(valid[`${key}`]).every(res => res === true)) {
                continue;
            }
            return false;
        }

        return true;
    }

    get can_download_application_form() {
        if (!this.is_profile_valid) {
            return false;
        }
        if (!this.is_billing_valid) {
            return false;
        }
        if (!this.is_bank_account_valid) {
            return false;
        }

        return true;
    }

    get gb_trn() {
        if (!Array.isArray(this.tax_reference_numbers)) {
            return new TaxReferenceNumber();
        }

        return this.tax_reference_numbers.find(trn => trn.jurisdiction === 'GB');
    }

    get other_trn() {
        if (!Array.isArray(this.tax_reference_numbers)) {
            return [];
        }

        return this.tax_reference_numbers.filter(trn => trn.jurisdiction !== 'GB');
    }

    get has_bank_account() {
        return this.bank_accounts && this.bank_accounts.length > 0;
    }

    get progress_percentage() {
        return this.onboarding_status_percentage;
    }

    get progress_colour() {
        if (this.progress_percentage === null) {
            return 'primaryBackground';
        }

        if (this.progress_percentage === 100) {
            return 'success';
        } else if (this.progress_percentage === 0) {
            return 'error';
        }

        return 'warning';
    }

    get progress_text_colour() {
        if (this.progress_percentage === null) {
            return 'primaryText';
        }

        return `${this.progress_colour}--text text--darken-1`;
    }

    get cash() {
        if (!this.cash_balance) {
            return { amount: 0, currency: 'GBP' };
        }

        return this.cash_balance;
    }

    get account_stage_status() {
        const statuses = Object.keys(InvestorAccountStageEnum).reduce((acc, key) => {
            acc[`${key}`] = InvestorAccountStageStatusEnum.INCOMPLETE;
            return acc;
        }, {});

        // Registration
        if (InvestorAccountStageEnum.REGISTRATION in statuses) {
            if (this.onboarding_status === InvestorRegistrationStatusEnum.COMPLETE) {
                statuses[InvestorAccountStageEnum.REGISTRATION] = InvestorAccountStageStatusEnum.COMPLETE;
            }
        }

        // Categorisation
        if (InvestorAccountStageEnum.CATEGORISATION in statuses) {
            if (this.category_expired) {
                statuses[InvestorAccountStageEnum.CATEGORISATION] = InvestorAccountStageStatusEnum.WARNING;
            } else if (this.category !== null) {
                statuses[InvestorAccountStageEnum.CATEGORISATION] = InvestorAccountStageStatusEnum.COMPLETE;
            }
        }

        // Profile
        if (InvestorAccountStageEnum.PROFILE in statuses) {
            if (this.is_profile_valid) {
                statuses[InvestorAccountStageEnum.PROFILE] = InvestorAccountStageStatusEnum.COMPLETE;
            }
        }

        // Appropriateness
        if (InvestorAccountStageEnum.APPROPRIATENESS in statuses) {
            if (this.is_appropriateness_valid) {
                statuses[InvestorAccountStageEnum.APPROPRIATENESS] = InvestorAccountStageStatusEnum.COMPLETE;
            }
        }

        // Bank Account
        if (InvestorAccountStageEnum.BANK_ACCOUNT in statuses) {
            if (this.is_bank_account_valid) {
                statuses[InvestorAccountStageEnum.BANK_ACCOUNT] = InvestorAccountStageStatusEnum.COMPLETE;
            }
        }

        // Application
        if (InvestorAccountStageEnum.APPLICATION in statuses) {
            if (this.has_applied) {
                statuses[InvestorAccountStageEnum.APPLICATION] = InvestorAccountStageStatusEnum.COMPLETE;
            }
        }

        // Authorised
        if (InvestorAccountStageEnum.AUTHORISATION in statuses) {
            if (this.is_authorised) {
                statuses[InvestorAccountStageEnum.AUTHORISATION] = InvestorAccountStageStatusEnum.COMPLETE;
            }
        }

        return Object.freeze(statuses);
    }

    //

    get fees_balance() {
        return {
            amount: this.$m(this.platform_fees_balance.amount)
                .add(this.adviser_initial_fees_balance.amount)
                .add(this.adviser_ongoing_fees_balance.amount).value,
            currency: 'GBP'
        };
    }
    get fees_overdue() {
        const amount = this.$m(this.fees_balance.amount).multiply(-1).value;
        return {
            amount: amount > 0 ? amount : 0,
            currency: 'GBP'
        };
    }
    get platform_fees_balance() {
        return {
            amount:
                this.fee_accounts?.reduce((total, account) => {
                    return total + account.platform_fees_balance?.amount || 0;
                }, 0) || 0,
            currency: 'GBP'
        };
    }
    get platform_fees_overdue() {
        const amount = this.$m(this.platform_fees_balance.amount).multiply(-1).value;
        return {
            amount: amount > 0 ? amount : 0,
            currency: 'GBP'
        };
    }
    get adviser_initial_fees_balance() {
        return {
            amount:
                this.fee_accounts?.reduce((total, account) => {
                    return total + account.adviser_initial_fees_balance?.amount || 0;
                }, 0) || 0,
            currency: 'GBP'
        };
    }
    get adviser_initial_fees_overdue() {
        const amount = this.$m(this.adviser_initial_fees_balance.amount).multiply(-1).value;
        return {
            amount: amount > 0 ? amount : 0,
            currency: 'GBP'
        };
    }
    get adviser_ongoing_fees_balance() {
        return {
            amount:
                this.fee_accounts?.reduce((total, account) => {
                    return total + account.adviser_ongoing_fees_balance?.amount || 0;
                }, 0) || 0,
            currency: 'GBP'
        };
    }
    get adviser_ongoing_fees_overdue() {
        const amount = this.$m(this.adviser_ongoing_fees_balance.amount).multiply(-1).value;
        return {
            amount: amount > 0 ? amount : 0,
            currency: 'GBP'
        };
    }

    get fees_paid() {
        return {
            amount: this.$m(this.platform_fees_paid.amount)
                .add(this.adviser_initial_fees_paid.amount)
                .add(this.adviser_ongoing_fees_paid.amount).value,
            currency: 'GBP'
        };
    }
    get platform_fees_paid() {
        return {
            amount:
                this.fee_accounts?.reduce((total, account) => {
                    return total + account.total_platform_fees?.amount || 0;
                }, 0) || 0,
            currency: 'GBP'
        };
    }
    get adviser_initial_fees_paid() {
        return {
            amount:
                this.fee_accounts?.reduce((total, account) => {
                    return total + account.total_adviser_initial_fees?.amount || 0;
                }, 0) || 0,
            currency: 'GBP'
        };
    }
    get adviser_ongoing_fees_paid() {
        return {
            amount:
                this.fee_accounts?.reduce((total, account) => {
                    return total + account.total_adviser_ongoing_fees?.amount || 0;
                }, 0) || 0,
            currency: 'GBP'
        };
    }

    get fee_reserve() {
        return {
            amount:
                this.fee_accounts?.reduce((total, account) => {
                    return total + account.total_fee_reserve?.amount || 0;
                }, 0) || 0,
            currency: 'GBP'
        };
    }
    get platform_fee_reserve() {
        return {
            amount:
                this.fee_accounts?.reduce((total, account) => {
                    return total + account.platform_reserve?.amount || 0;
                }, 0) || 0,
            currency: 'GBP'
        };
    }
    get adviser_ongoing_fee_reserve() {
        return {
            amount:
                this.fee_accounts?.reduce((total, account) => {
                    return total + account.adviser_ongoing_reserve?.amount || 0;
                }, 0) || 0,
            currency: 'GBP'
        };
    }

    get cleared_funds_required() {
        return {
            amount: this.$m(this.fees_overdue_and_reserve.amount).add(this.total_proposals_amount.amount || 0).value,
            currency: 'GBP'
        };
    }
    get fees_overdue_and_reserve() {
        return {
            amount: this.$m(this.fees_overdue.amount).add(this.fee_reserve.amount).value,
            currency: 'GBP'
        };
    }
    get total_proposals_amount() {
        return {
            amount: this.proposals_amount?.amount || 0,
            currency: 'GBP'
        };
    }
    get total_cash_balance() {
        return {
            amount: this.cash_balance?.amount || 0,
            currency: 'GBP'
        };
    }
    get cash_balance_deficit() {
        const amount = this.$m(this.cleared_funds_required.amount).subtract(this.total_cash_balance.amount).value;
        return {
            amount: amount > 0 ? amount : 0,
            currency: 'GBP'
        };
    }
}

export default Investor;
